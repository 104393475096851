import React  from 'react';
import '../css/intro.css';

const Intro = () => {
  // Add array of word-image pairs
  const contentItems = [
    { word: 'Timeless', image: '/assets/portfolio/featured/timelesselegance.jpg' },
    { word: 'Authentic', image: '/assets/portfolio/featured/bestofjune.jpg' },
    { word: 'Editorial', image: '/assets/portfolio/featured/oldfilm.jpg' },
    { word: 'Dreamy', image: '/assets/portfolio/featured/healiness.jpg' },
    { word: 'Comfortable', image: '/assets/portfolio/featured/lilnewyorker.jpg' },
    { word: 'Memorable', image: '/assets/portfolio/featured/firstdate.jpg' },
    { word: 'Aesthetic', image: '/assets/portfolio/featured/whiteroses.jpg' },
    { word: 'Fun', image: '/assets/portfolio/featured/aredknot.jpg' },
    { word: 'Cinematic', image: '/assets/portfolio/featured/blushofolympus.jpg' },
    { word: 'Photojournalistic', image: '/assets/portfolio/featured/harmonies.jpg' }
  ];

  return (
    <section className="intro">
      <div className="row">
        <h4 className="fade-up">
          An Exceptional Experience and Imagery that is
        </h4>
      </div>
      <div className="intro-content">
        {contentItems.map((item, index) => (
          <div key={index} className="word-container">
            <h3 className="fade-up">
              {item.word}
            </h3>
            <div className={`hover-image ${index % 2 === 0 ? 'left' : 'right'}`}>
              <img src={item.image} alt={item.word} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Intro;
