import React from "react";
import { useNavigate } from 'react-router-dom';
import "../css/work.css";

const Work = () => {
  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`/portfolio`, { state: { activeCategory: category } });
  };
  return (
    <section id="portfolio" className="work">
      <h4 className="subtext left heading">&mdash; Our Work</h4>
      <div className="work-content">
      <div className="top-line"></div>

      {/* Weddings Section */}
        <div className="work-item row-reverse" onClick={() => handleClick('Weddings')}>
          <div className="work-image">
            <img src="/assets/portfolio/weddings.jpg" alt="Wedding couple" />
          </div>
          <div className="work-text">
            <h1 className="bigNumber">01</h1>
            <h2>Weddings & Events</h2>
            <p>This is the best chapter of your book, yet</p>
            <a className="custom-link">Portfolio</a>
            <br/>
          </div>
        </div>
        <h4 className="subtext right">New beginnings</h4>
        {/* Maternity Section */}
        <div className="work-item" onClick={() => handleClick('Maternity')}>
          <div className="work-image">
            <img src="/assets/portfolio/maternity.jpg" alt="Maternity shoot" />
          </div>
          <div className="work-text">
            <h1 className="bigNumber">02</h1>
            <h2>Maternity</h2>
            <p>This is when life begins and love never ends</p>
            <a className="custom-link">Portfolio</a>
            <br/>
          </div>
        </div>
    
    {/* couples Section */}
    <h4 className="subtext left">All about love</h4>
    <div className="work-item row-reverse" onClick={() => handleClick('Couples')}>
    <div className="work-image">
          <img src="/assets/portfolio/engagements.jpg" alt="Portraits & Family shoot" />
        </div>
        <div className="work-text">
          <h1 className="bigNumber">03</h1>
          <h2>Proposals & Engagements</h2>
          <p>These are the moments that speak volumes</p>
          <a className="custom-link">Portfolio</a>
          <br/>
        </div>
      </div>

    {/* Portraits Section */}
    <div className="work-item" onClick={() => handleClick('Branding')}>
      <div className="work-image">
          <img src="/assets/portfolio/portraits.jpg" alt="Portraits & Family shoot" />
      </div>
      <div className="work-text">
          <h1 className="bigNumber">04</h1>
          <h2>Portraits & Family</h2>
          <p>This is for life's greatest blessings</p>
          <a className="custom-link">Portfolio</a>
          <br/>
      </div>
    </div>
    <h4 className="subtext right">Captured Intentionally</h4>
    </div>
    </section>
  );
};

export default Work;
