import React, { useEffect, useRef,useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import '../css/about.css';

const About = () => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);
  const readMoreRef = useRef(null);

  const profilePhotoRef = useRef(null);
  const rightGridRef = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById('about');
      const scrollValue = window.scrollY - aboutSection.offsetTop;

      if (aboutSection) {
        const sectionTop = aboutSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight && sectionTop > -aboutSection.offsetHeight) {
          const parallaxValue = scrollValue * 0.1; // Adjust parallax strength

          if (profilePhotoRef.current) {
            profilePhotoRef.current.style.transform = `translateY(${parallaxValue}px)`;
          }
          if (rightGridRef.current) {
            rightGridRef.current.style.transform = `translateY(${-parallaxValue}px)`;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: '#about-content',
      start: 'top center',
      end: 'bottom center',
      onEnter: () => document.body.style.backgroundColor = '#1e1e1c',
      onLeave: () => document.body.style.backgroundColor = '',
      onEnterBack: () => document.body.style.backgroundColor = '#1e1e1c',
      onLeaveBack: () => document.body.style.backgroundColor = '',
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  useEffect(() => {
    if (readMoreRef.current) {
      if (isReadMoreVisible) {
        readMoreRef.current.style.height = `${readMoreRef.current.scrollHeight}px`;
      } else {
        readMoreRef.current.style.height = '0px';
      }
    }
  }, [isReadMoreVisible]);

  const toggleReadMore = () => {
    setIsReadMoreVisible(!isReadMoreVisible);
    console.log(isReadMoreVisible);
  };

  return (
    <section id="about">
      {/* <div className="circle"></div> */}
      <div className="about-content" id='about-content'>
        <div className="profile-photo" ref={profilePhotoRef}>
          <img alt="profile-photo" src="./assets/profile-photo2.jpg" />
        </div>
        <div className="right-grid" ref={rightGridRef}>
          <h2 className='cursive'>{isReadMoreVisible ? `hello, i'm Amna` : 'hi there,'}</h2>
          <p className={`readmore ${isReadMoreVisible ? 'visible' : 'hidden'}`}
            ref={readMoreRef}>
            I'm a Toronto based photographer soaking in the vibrant mix of this multicultural city over the past five years and making it the muse for my photo journey! I enjoy making my albums into a narrative that tells your compelling story. With a knack for attention to detail, I personally handle all editing to ensure you get a perfect blend of timeless, candid, and posed shots with whichever editing style you prefer! Beyond capturing moments, I lead a dual life as a creative coder at a digital agency. Dark chocolates, travel escapades, and competitive games of ping pong fuel my downtime activities. And I also draw for fun <a href='https://www.instagram.com/galwhodraws/' target='_blank'>@galwhodraws</a>.</p>
          <p>
            As a photographer, I believe creating from a place of love can make the world a more beautiful and meaningful place.
            I pour my heart into capturing your emotions, delivering a gallery of thoughtfully composed, authentic photos that will make your heart skip a beat!
          </p>
          <p>
            I'm eagerly prepared to take on projects, no matter their size so let's collaborate and bring your vision to life!
          </p>
          <a className="unstyled-link" onClick={toggleReadMore}>{isReadMoreVisible ? '♡' : 'Read More'}</a>
          {/* <div className="btn-container">
            <button className="accent-btn">
              <span className="btn-circle"></span>
              <span className="btn-text">Go Behind The Scenes</span>
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
