import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const initTextAnimations = () => {
  // Select all elements with fade-up class across the entire app
  gsap.fromTo(
    '.fade-up',
    { opacity: 0, y: 40 },
    {
      opacity: 1,
      y: 0,
      duration: 0.5,
      ease: 'power2.out',
      stagger: 0.1,
      scrollTrigger: {
        trigger: '.fade-up',
        start: 'top 90%',
        toggleActions: 'play none none none',
      },
    }
  );
}; 