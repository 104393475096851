export const images = {
  Featured: [
    {
      src: "/assets/portfolio/featured/9.webp",
      title: "Timeless Elegance",
      name: "Larissa and Ben",
      date: "31 . 08 . 2024",
      description: "An intimate ceremony, held in the historic room of St. George’s Golf & Country Club, was a truly magical experience. Surrounded by their closest loved ones, I captured the timeless moment as they exchanged their heartfelt vows."
    },
    {
      src: "./assets/portfolio/featured/1.webp",
      title: "White Roses",
      name: "Yazel and Ahmed",
      date: "28 . 04 . 2024",
      description: "The newlyweds sharing cherished moments right after their Nikkah ceremony."
    },

    {
      src: "./assets/portfolio/featured/2.webp",
      title: "Sand & Sea",
      name: "Sam and Liam",
      date: "12 . 04 . 2024",
      description: "A joyful photoshoot announcing the arrival of their first baby."
    },

    {
      src: "./assets/portfolio/featured/4.webp",
      title: "Castles & Dreams",
      name: "Mandeep and Manpreet",
      date: "5 . 08 . 2024",
      description: "A romantic prewedding shoot in a dreamy setting."
    },
    {
      src: "./assets/portfolio/featured/5.webp",
      title: "Lil New Yorker",
      name: "Cardine and Paul",
      date: "12 . 05 . 2024",
      description: "A maternity shoot blending urban energy."
    },
    {
      src: "./assets/portfolio/featured/6.webp",
      title: "A Red Knot",
      name: "Sejun and Lucy",
      date: "24 . 06 . 2024",
      description: "A romantic sunlit kiss marking the beginning of a new chapter."
    }
  ],
  Weddings: [
    {
      src: "/assets/portfolio-item/TimelessElegance/18.webp",
      title: "Timeless Elegance",
      name: "Larissa and Ben",
      date: "31 . 08 . 2024",
      description: "An intimate ceremony, held in the historic room of St. George’s Golf & Country Club, was a truly magical experience. Surrounded by their closest loved ones, I captured the timeless moment as they exchanged their heartfelt vows."
    },
    {
      src: "/assets/portfolio-item/Union/3.webp",
      title: "Union",
      name: "Alysia and Aleem",
      date: "18 . 02 . 2024",
      description: "A prewedding shoot set in the heart of downtown Toronto."
    },
    {
      src: "/assets/portfolio-item/Seoulmates/7.webp",
      title: "Seoulmates",
      name: "Michael and Fionn",
      date: "24 . 05 . 2023",
      description: "An intimate exchange of vows in the Toronto City Hall."
    },
    {
      src: "/assets/portfolio-item/Princessgate/1.webp",
      title: "Princess Gate",
      name: "Samantha and Matthew",
      date: "31 . 08 . 2024",
      description: "A lovely couples shoot on a chilly, fog-filled spring evening in downtown Toronto."
    },
    {
      src: "/assets/portfolio-item/Oldfilm/9.webp",
      title: "Old Film",
      name: "Zakia and Erhan",
      date: "10 . 05 . 2024",
      description: "A film-like elopement shoot in Toronto."
    },
    {
      src: "/assets/portfolio-item/Whiteroses/15.webp",
      title: "White Roses",
      name: "Yazel and Ahmed",
      date: "28 . 04 . 2024",
      description: "The bride and groom's nikkah ceremony and couple's shoot."
    },
    {
      src: "/assets/portfolio-item/Firstdate/4.webp",
      title: "First Date",
      name: "Meher and Ranveet",
      date: "24 . 06 . 2024",
      description: "Celebrating the beautiful wedding that followed after their perfect date."
    }
  ],
  Couples: [
    {
      src: "/assets/portfolio-item/Aredknot/5.webp",
      title: "A Red Knot",
      name: "Sejun and Lucy",
      date: "24 . 06 . 2024",
      description: "A romantic sunlit kiss marking the beginning of a new chapter."
   },
    {
      src: "/assets/portfolio-item/Castlesanddreams/6.webp",
      title: "Castles & Dreams",
      name: "Mandeep and Manpreet",
      date: "5 . 08 . 2024",
      description: "A romantic prewedding shoot in a dreamy setting." 
    },
    {
      src: "/assets/portfolio-item/Whisperedvows/10.webp",
      title: "Whispered Vows",
      name: "Faye and Alex",
      date: "24 . 08 . 2024",
      description: "An intimate proposal unfolding in a secluded spot in Trillium park."
    },
    {
      src: "/assets/portfolio-item/Summerglow/8.webp",
      title: "Summer Glow",
      name: "Amy and Kevin",
      date: "24 . 08 . 2024",
      description: "Shooting the cover for a heartfelt love song, set against the serene backdrop of botanical gardens"
    },
    {
      src: "/assets/portfolio-item/Blushofolympus/4.webp",
      title: "Blush of Olympus",
      name: "Shawana and Awad",
      date: "12 . 05 . 2024",
      description: "A couples shoot in the gardens of Guild Inn Estate"
    }
  ],
  Maternity: [
    {
      src: "/assets/portfolio-item/Sandsea/9.webp",
      title: "Sand & Sea",
      name: "Sam and Liam",
      date: "12 . 04 . 2024",
      description: "A joyful photoshoot announcing the arrival of their first baby."
    },
    {
      src: "/assets/portfolio-item/Lilnewyorker/5.webp",
      title: "Lil New Yorker",
      name: "Cardine and Paul",
      date: "12 . 05 . 2024",
      description: "A maternity shoot blending urban energy"
    },
    {
      src: "/assets/portfolio-item/Newbeginnings/8.webp",
      title: "New Beginnings",
      name: "Amna and Asad",
      date: "15 . 01 . 2024",
      description: "A maternity shoot at Old Mills, celebrating the anticipation and joy of welcoming their firstborn."
    }
  ],
  Branding: [
    {
      src: "/assets/portfolio-item/Bestofjune/4.webp",
      title: "Best of June",
      description: "A portrait session capturing the author’s creativity and essence."
    },
    {
      src: "/assets/portfolio-item/Healiness/3.webp",
      title: "Healiness",
      description: "A branding portrait shoot showcasing the heart and personality of the business owners."
    },
    {
      src: "/assets/portfolio-item/Harmonies/8.webp",
      title: "Harmonies",
      description: "Shooting the Spotify cover for a heartfelt love song, set against the serene backdrop of a greenhouse"
    }
  ],
  Travel: [
    {
      src: "/assets/portfolio-item/Tulum/2.webp",
      title: "Tulum",
      description: "Street photography on a tourist busy Tulum."
    },
    {
      src: "/assets/portfolio-item/Cancun/2.webp",
      title: "Cancun",
      description: "A vibrant travel shoot in Cancun, capturing the beauty of its beaches and tropical vibes."
    },
    {
      src: "/assets/portfolio-item/Banff/3.webp",
      title: "Banff",
      description: "A spring visit to Banff and some nature photography."
    }
  ]
};