import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToSectionLink from './ScrollToSectionLink';
import { useNavigate } from 'react-router-dom';
import '../css/header.css';

gsap.registerPlugin(ScrollTrigger);

function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [pastHeaderSection, setpastHeaderSection] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if(!menuOpen){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflowY = 'auto';
    }
  };

  
  const handleClick = (page) => {
    navigate(`/` + page);
  };

  useEffect(() => {
    const header = document.querySelector('.header');

    ScrollTrigger.create({
      start: 'top -80',
      end: 'max',
      toggleClass: { className: 'header--scrolled', targets: header },
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);


  useEffect(() => {
    // Check for #header presence on route change
    const headerElement = document.querySelector('#header');
    if (headerElement) {
      ScrollTrigger.create({
        trigger: headerElement,
        start: 'bottom top',
        onEnter: () => {
          console.log('Entering header section');
          setpastHeaderSection(true);
        },
        onLeaveBack: () => {
          console.log('Leaving header section');
          setpastHeaderSection(false);
        },
        markers: false,
      });
    } else {
      // Ensure nav-header is visible if #header is absent
      setpastHeaderSection(false);
    }
  }, [location]);

  return (
    <>
      <div>
        <header className="header" id='header'>
          <div className={`nav-header ${pastHeaderSection ? 'hidden' : ''}`}>
            <ul className="nav">
              <li>
                <ScrollToSectionLink to="about">About</ScrollToSectionLink>
              </li>
              <li>
                <div onClick={() => handleClick('portfolio')}>Portfolio</div>
              </li>
              <li>
                <ScrollToSectionLink to="services">Services</ScrollToSectionLink>
              </li>
                <a href="https://calendly.com/photosbyamna/60min" target="_blank" rel="noreferrer">
                  Book Minis
                </a>
              {/* <li>
                <ScrollToSectionLink to="contact">Blog</ScrollToSectionLink>
              </li> */}
              <li>
                <ScrollToSectionLink to="contact">Inquire</ScrollToSectionLink>
              </li>
                <a className="social-link" href="https://www.instagram.com/galwhosnaps/?hl=en" target="_blank" rel="noreferrer">
                  <img src="/assets/social-icons/instagram.svg" alt="Instagram" />
                </a>
                {/* <a className="social-link" href="https://www.facebook.com/profile.php?id=61571146656233" target="_blank" rel="noreferrer">
                  <img src="/assets/social-icons/facebook.svg" alt="Facebook" />
                </a>
                <a className="social-link" href="https://pin.it/SD36ElTXT" target="_blank" rel="noreferrer">
                  <img src="/assets/social-icons/pinterest.svg" alt="Pinterest" />
                </a> */}
            </ul>
          </div>
          <div className={`hamburger ${menuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>MENU</div>
        </header>
      </div>

      <div className={`nav-menu ${menuOpen ? 'is-active' : ''}`}>

        <ul className="nav">
        <button className="close-btn" onClick={toggleMenu}>
          <span></span>
          <span></span>
        </button>
        <li onClick={toggleMenu}>
            <ScrollToSectionLink to="services">Services</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="about">About</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="portfolio">Portfolio</ScrollToSectionLink>
          </li>
          <li onClick={toggleMenu}>
                <a href="https://calendly.com/photosbyamna/60min" target="_blank" rel="noreferrer">
                  Book Minis
                </a>
          </li>
          <li onClick={toggleMenu}>
            <ScrollToSectionLink to="contact">Inquire</ScrollToSectionLink>
          </li>
        </ul>

        <div className='watermark-container'>
          <div className="rotating-logo">
              <img className="camera" src="/assets/logo-camera-white.png" alt="Camera" />
              <img className="text" src="/assets/logo-text-white.png" alt="Text" />
          </div>
          <div className='social-links light'>
            <a className='instagram' href="https://www.instagram.com/galwhosnaps/?hl=en" target='_blank' rel="noreferrer">INSTAGRAM</a>
            {/* <a className='pinterest' href="https://pin.it/7BwfsCyN5" target='_blank' rel="noreferrer">PINTEREST</a>
            <a className='facebook' href="https://www.facebook.com/profile.php?id=61571146656233" target='_blank' rel="noreferrer">FACEBOOK</a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
