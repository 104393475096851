// data-portfolio.js
const portfolioData = {

  TimelessElegance: {
    title: "Timeless Elegance",
    description: [
      "31 . 08 . 2024",
      "On the last day of August, I had the pleasure of photographing Larissa and Ben's long-anticipated wedding. Their intimate ceremony, held in the historic room of St. George’s Golf & Country Club, was a truly magical experience. Surrounded by their closest loved ones, I captured the timeless moment as they exchanged their heartfelt vows.",
      "Following the ceremony, we ventured into the gardens to create some beautifully creative shots bathed in sunlight. The final images, edited in bright, natural hues, vividly reflect the essence of their special day and the joy shared with their families.",
      "Larissa and Ben, your day was truly magical and intimate. Thank you for the thoughtful note and the succulent plant; they are both deeply cherished. I’m grateful for the opportunity to share your beautiful photos and be a part of your special day."
    ],
    bannerImage: "/assets/portfolio-item/TimelessElegance/2.webp",
    images: [
      "/assets/portfolio-item/TimelessElegance/1.webp",
      "/assets/portfolio-item/TimelessElegance/2.webp",
      "/assets/portfolio-item/TimelessElegance/3.webp",
      "/assets/portfolio-item/TimelessElegance/4.webp",
      "/assets/portfolio-item/TimelessElegance/5.webp",
      "/assets/portfolio-item/TimelessElegance/6.webp",
      "/assets/portfolio-item/TimelessElegance/7.webp",
      "/assets/portfolio-item/TimelessElegance/8.webp",
      "/assets/portfolio-item/TimelessElegance/9.webp",
      "/assets/portfolio-item/TimelessElegance/10.webp",
      "/assets/portfolio-item/TimelessElegance/11.webp",
      "/assets/portfolio-item/TimelessElegance/12.webp",
      "/assets/portfolio-item/TimelessElegance/13.webp",
      "/assets/portfolio-item/TimelessElegance/14.webp",
      "/assets/portfolio-item/TimelessElegance/15.webp",
      "/assets/portfolio-item/TimelessElegance/16.webp",
      "/assets/portfolio-item/TimelessElegance/17.webp",
      "/assets/portfolio-item/TimelessElegance/18.webp",
      "/assets/portfolio-item/TimelessElegance/19.webp",
      "/assets/portfolio-item/TimelessElegance/20.webp",
      "/assets/portfolio-item/TimelessElegance/21.webp",
    ]
  },

    SandandSea: {
      title: "Sand & Sea",
      description: [
        "12 . 04 . 2024",
        "Photographing Sam and Liam’s pregnancy announcement at Scarborough Bluffs in the spring was a true delight. Their excitement for the arrival of their baby girl in October radiated through every moment of the shoot. One of the sweetest couples I’ve ever worked with, their genuine smiles and joyful energy adding a special touch to the session. They embraced every playful pose and didn’t shy away from getting a bit sandy and wet for the perfect shot. The shoot beautifully captured the essence of their love, with the sand and sea blending seamlessly under the warm, golden hues of the setting sun.",
        "Sam and Liam, it was a joy to capture your pregnancy announcement. Your excitement and connection are beautifully reflected in these photos, and they will serve as cherished memories of this special time as you await your baby girl."
      ],
      bannerImage: "/assets/portfolio-item/SandSea/2.webp",
      images: [
        "/assets/portfolio-item/SandSea/1.webp",
        "/assets/portfolio-item/SandSea/2.webp",
        "/assets/portfolio-item/SandSea/3.webp",
        "/assets/portfolio-item/SandSea/4.webp",
        "/assets/portfolio-item/SandSea/5.webp",
        "/assets/portfolio-item/SandSea/6.webp",
        "/assets/portfolio-item/SandSea/7.webp",
        "/assets/portfolio-item/SandSea/8.webp",
        "/assets/portfolio-item/SandSea/9.webp",
        "/assets/portfolio-item/SandSea/10.webp",
        "/assets/portfolio-item/SandSea/11.webp",
        "/assets/portfolio-item/SandSea/12.webp"
      ]
    },

    Union: {
      title: "Union",
      description: [
        "18 . 02 . 2024",
        "Alysia and Aleem’s couple shoot, set against the iconic Union Station in Downtown Toronto during the cold winter season, marked my very first professional photoshoot. Armed with a Pinterest vision board, Alysia and I set out to bring her dream to life.",
        "Despite being their first photoshoot experience, Alysia and Aleem absolutely nailed it. The session, edited in timeless hues, perfectly captures their union in a montage that beautifully reflects their love story.",
        "Alysia and Aleem, thank you for your appreciation of my work. It was a treat to work with you - and hopefully will again!"
      ],
      bannerImage: "/assets/portfolio-item/Union/2.webp",
      images: [
        "/assets/portfolio-item/Union/1.webp",
        "/assets/portfolio-item/Union/2.webp",
        "/assets/portfolio-item/Union/3.webp",
        "/assets/portfolio-item/Union/4.webp",
        "/assets/portfolio-item/Union/5.webp",
        "/assets/portfolio-item/Union/6.webp",
        "/assets/portfolio-item/Union/7.webp",
        "/assets/portfolio-item/Union/8.webp",
        "/assets/portfolio-item/Union/9.webp",
        "/assets/portfolio-item/Union/10.webp",
        "/assets/portfolio-item/Union/11.webp",
        "/assets/portfolio-item/Union/12.webp"
      ]

    },

    CastlesandDreams: {
      title: "Castles and Dreams",
      description: [
        "5 . 08 . 2024",
        "As Mandeep and Manpreet prepared for their winter wedding in India, they reached out to me for a pre-wedding couples shoot. Mandeep sought my advice on outfit colors and location, and after reviewing several venue options, she chose the stunning Casa Loma—a choice I was thrilled about, as I had longed to shoot there.",
        "Though the day turned out to be unexpectedly hot and the anticipated sunset hues didn’t materialize, we made the most of the situation. Casa Loma’s photo permit requirements for outdoor shooting led us to explore the enchanting public gardens nearby. This decision proved to be a delightful success, and the shoot was nothing short of magical.",
        "Deep and Preet, capturing your pre-wedding shoot was a joy!"
      ],
      bannerImage: "/assets/portfolio-item/CastlesandDreams/2.webp",
      images: [
        "/assets/portfolio-item/CastlesandDreams/1.webp",
        "/assets/portfolio-item/CastlesandDreams/2.webp",
        "/assets/portfolio-item/CastlesandDreams/3.webp",
        "/assets/portfolio-item/CastlesandDreams/4.webp",
        "/assets/portfolio-item/CastlesandDreams/5.webp",
        "/assets/portfolio-item/CastlesandDreams/6.webp",
        "/assets/portfolio-item/CastlesandDreams/7.webp",
        "/assets/portfolio-item/CastlesandDreams/8.webp",
        "/assets/portfolio-item/CastlesandDreams/9.webp",
        "/assets/portfolio-item/CastlesandDreams/10.webp",
        "/assets/portfolio-item/CastlesandDreams/11.webp",
        "/assets/portfolio-item/CastlesandDreams/12.webp"
      ]
    },

    LilNewYorker: {
      title: "Lil New Yorker",
      description: [
        "12 . 05 . 2024",
        "In the midst of my busiest month ever, I received an urgent inquiry around 11pm for a couple's shoot the very next day. Without hesitation, I responded to Paul, who was seeking a session at a not so common location.",
        "The next morning, I took the subway downtown to the Intercontinental Hotel, unsure of why the shoot was set there but planning to scout nearby spots for outdoor options. When I arrived, Paul greeted me in the lobby by himself, showing me potential photo spots within the hotel.",
        "As I set up my equipment, Cardine appeared, and it was then I realized this was a maternity shoot—a surprise that made my heart race as I quickly recalled maternity poses. Balancing a conversation with Cardine and Paul about whether to include their shiny diamonds, I simultaneously brainstormed for creative maternity ideas. I shot both with and without flash, and Cardine’s radiant confidence grew as she saw how stunning she looked in the photos. The skyscraper backdrop added a chic New York touch to the Toronto setting. I completed the session by delivering a gallery with a timeless and edgy feel, leaving Paul and Cardine thrilled as they prepared to welcome their first baby together.",
        "Cardine and Paul, thank you for allowing me to capture your beautiful moments and for being so wonderful to work with. Your warmth and joy truly shone through in every shot."
      ],
      bannerImage: "/assets/portfolio-item/LilNewYorker/2.webp",
      images: [
        "/assets/portfolio-item/LilNewYorker/1.webp",
        "/assets/portfolio-item/LilNewYorker/2.webp",
        "/assets/portfolio-item/LilNewYorker/3.webp",
        "/assets/portfolio-item/LilNewYorker/4.webp",
        "/assets/portfolio-item/LilNewYorker/5.webp",
        "/assets/portfolio-item/LilNewYorker/6.webp",
        "/assets/portfolio-item/LilNewYorker/7.webp",
        "/assets/portfolio-item/LilNewYorker/8.webp",
        "/assets/portfolio-item/LilNewYorker/9.webp"
      ]
    },

    ARedKnot: {
      title: "A Red Knot",
      description: [
        "24 . 06 . 2024",
        "From my hidden spot behind the bushes, I captured the magical moment when Sejun surprised Lucy by having her close her eyes and pull out a red thread. He threaded their rings onto it, and Lucy traced the thread with her fingers to find her ring.",
        "Once they exchanged their heartfelt 'yes,' we celebrated their commitment with a dreamy photoshoot. Lucy and Sejun were enthusiastic about taking off their shoes and playfully splashing in the water.",
        "Edited in warm, inviting tones, this gallery beautifully preserves the love and joy shared by Sejun and Lucy.",
        "Sejun and Lucy, thank you for letting me capture your special moments and for your joyful spirit throughout the shoot. Your enthusiasm made the experience truly memorable."
      ],
      bannerImage: "/assets/portfolio-item/ARedKnot/2.webp",
      images: [
        "/assets/portfolio-item/ARedKnot/1.webp",
        "/assets/portfolio-item/ARedKnot/2.webp",
        "/assets/portfolio-item/ARedKnot/3.webp",
        "/assets/portfolio-item/ARedKnot/4.webp",
        "/assets/portfolio-item/ARedKnot/5.webp",
        "/assets/portfolio-item/ARedKnot/6.webp",
        "/assets/portfolio-item/ARedKnot/7.webp",
        "/assets/portfolio-item/ARedKnot/8.webp",
        "/assets/portfolio-item/ARedKnot/9.webp",
        "/assets/portfolio-item/ARedKnot/10.webp",
        "/assets/portfolio-item/ARedKnot/11.webp",
        "/assets/portfolio-item/ARedKnot/12.webp",
        "/assets/portfolio-item/ARedKnot/13.webp",
        "/assets/portfolio-item/ARedKnot/14.webp",
        "/assets/portfolio-item/ARedKnot/15.webp",
      ]
    },

    WhiteRoses: {
      title: "White Roses",
      description: [
        "28 . 04 . 2024",
        "Photographing Yazel and Ahmet’s Nikkah at the Bosnian Mosque was a memorable experience. Their intimate ceremony, surrounded by loved ones, was filled with genuine emotion.",
        "Arriving early allowed me to capture a truly heartwarming moment: Ahmet presenting Yazel with a bouquet of white roses. Seeing Yazel’s reaction—a mix of surprise and pure joy—added a touching detail to the day that I was delighted to photograph.",
        "Later, we ventured to Hamilton Beach for a couple's shoot, where the relaxed atmosphere perfectly mirrored their natural connection. The beach, bathed in the golden hues of the sunset, provided a stunning backdrop for capturing their smiles, laughter, and tender moments.",
        "Yazel and Ahmet, it was a pleasure to capture your day. These photos reflect the love and happiness you both shared and will be cherished memories of your special time."
      ],
      
    
      bannerImage: "/assets/portfolio-item/WhiteRoses/1.webp",
      images: [
        "/assets/portfolio-item/WhiteRoses/1.webp",
        "/assets/portfolio-item/WhiteRoses/2.webp",
        "/assets/portfolio-item/WhiteRoses/3.webp",
        "/assets/portfolio-item/WhiteRoses/4.webp",
        "/assets/portfolio-item/WhiteRoses/5.webp",
        "/assets/portfolio-item/WhiteRoses/6.webp",
        "/assets/portfolio-item/WhiteRoses/7.webp",
        "/assets/portfolio-item/WhiteRoses/8.webp",
        "/assets/portfolio-item/WhiteRoses/9.webp",
        "/assets/portfolio-item/WhiteRoses/10.webp",
        "/assets/portfolio-item/WhiteRoses/11.webp",
        "/assets/portfolio-item/WhiteRoses/12.webp",
        "/assets/portfolio-item/WhiteRoses/13.webp",
        "/assets/portfolio-item/WhiteRoses/14.webp",
        "/assets/portfolio-item/WhiteRoses/15.webp",
        "/assets/portfolio-item/WhiteRoses/16.webp",
        "/assets/portfolio-item/WhiteRoses/17.webp",
        "/assets/portfolio-item/WhiteRoses/18.webp",
        "/assets/portfolio-item/WhiteRoses/19.webp",
        "/assets/portfolio-item/WhiteRoses/20.webp",
        "/assets/portfolio-item/WhiteRoses/21.webp",
      ]
    },

    BlushofOlympus: {
      title: "Blush of Olympus",
      description: [
        "12 . 05 . 2024",
        "In the enchanting gardens of Guild Inn Estate, I had the pleasure of photographing Shawana and Awad amidst stunning Roman sculptures, capturing their post-nikkah moments. Nestled in the heart of Scarborough, this picturesque garden perfectly matched Shawana’s vision for her shoot.",
        "I edited the gallery in soft blush vintage hues to complement Shawana's elegant saree and the roses she adorned herself with, creating a harmonious blend with Awad’s sophisticated grey attire. The result was a truly perfect combination that beautifully reflected their aura.",
        "Shawana and Awad, it was a delight to photograph your post-nikkah session."
       ],
      bannerImage: "/assets/portfolio-item/BlushofOlympus/2.webp",
      images: [
        "/assets/portfolio-item/BlushofOlympus/1.webp",
        "/assets/portfolio-item/BlushofOlympus/2.webp",
        "/assets/portfolio-item/BlushofOlympus/3.webp",
        "/assets/portfolio-item/BlushofOlympus/4.webp",
        "/assets/portfolio-item/BlushofOlympus/5.webp",
        "/assets/portfolio-item/BlushofOlympus/6.webp",
        "/assets/portfolio-item/BlushofOlympus/7.webp",
        "/assets/portfolio-item/BlushofOlympus/8.webp",
        "/assets/portfolio-item/BlushofOlympus/9.webp",
      ]
    },

    FirstDate: {
      title: "First Date",
      description: [
        "24 . 06 . 2024",
        "Meherzad and Ranveet’s love story began on their first date, and Meher knew from that moment that Ravneet was the one. Before their grand wedding celebration in India, they wanted to capture the significance of their official marriage in Toronto. After a romantic couples shoot at the RC Harris Filtration Plant in Scarborough, we moved to their condo’s party room for an intimate ceremony with their closest friends and family. The photos were edited to complement Ranveet’s turquoise-green dress and Meher’s elegant summer suit, capturing their special day beautifully.",
        "Meher and Ranveet, capturing your Toronto wedding was an honor."
      ],
      bannerImage: "/assets/portfolio-item/FirstDate/2.webp",
      images: [
        "/assets/portfolio-item/FirstDate/1.webp",
        "/assets/portfolio-item/FirstDate/2.webp",
        "/assets/portfolio-item/FirstDate/3.webp",
        "/assets/portfolio-item/FirstDate/4.webp",
        "/assets/portfolio-item/FirstDate/5.webp",
        "/assets/portfolio-item/FirstDate/6.webp",
        "/assets/portfolio-item/FirstDate/7.webp",
        "/assets/portfolio-item/FirstDate/8.webp",
        "/assets/portfolio-item/FirstDate/9.webp",
        "/assets/portfolio-item/FirstDate/10.webp",
        "/assets/portfolio-item/FirstDate/11.webp",
        "/assets/portfolio-item/FirstDate/12.webp",
      ]
    },

    WhisperedVows: {
      title: "Whispered Vows",
      description: [
        "24 . 08 . 2024",
        "Alex proposed to Faye in the heart of Trillium Park, in Downtown Toronto, at a secluded spot just as Faye had envisioned. I gave them the space to savor their private moment, and once they were ready, I began the shoot. I captured them through the trees, by the water, and along the path, blending both posed and candid shots in a cinematic style.",
        "Alex and Faye, it was so wonderful to witness and capture your proposal at Trillium Park."
      ],
      bannerImage: "/assets/portfolio-item/WhisperedVows/2.webp",
      images: [
        "/assets/portfolio-item/WhisperedVows/1.webp",
        "/assets/portfolio-item/WhisperedVows/2.webp",
        "/assets/portfolio-item/WhisperedVows/3.webp",
        "/assets/portfolio-item/WhisperedVows/4.webp",
        "/assets/portfolio-item/WhisperedVows/5.webp",
        "/assets/portfolio-item/WhisperedVows/6.webp",
        "/assets/portfolio-item/WhisperedVows/7.webp",
        "/assets/portfolio-item/WhisperedVows/8.webp",
        "/assets/portfolio-item/WhisperedVows/9.webp",
        "/assets/portfolio-item/WhisperedVows/10.webp",
        "/assets/portfolio-item/WhisperedVows/11.webp",
        "/assets/portfolio-item/WhisperedVows/12.webp",
        "/assets/portfolio-item/WhisperedVows/13.webp",
        "/assets/portfolio-item/WhisperedVows/14.webp",
        "/assets/portfolio-item/WhisperedVows/15.webp",
        "/assets/portfolio-item/WhisperedVows/16.webp",
        "/assets/portfolio-item/WhisperedVows/17.webp",
        "/assets/portfolio-item/WhisperedVows/18.webp",
        "/assets/portfolio-item/WhisperedVows/19.webp",
      ]
    },

    NewBeginnings: {
      title: "New Beginnings",
      description: [
        "15 . 01 . 2024",
        "Just a week before their first child was due, my friends Amna and Asad asked me to capture their maternity shoot. Living in Etobicoke, Amna chose the nearby Old Erin Mills as the location. Though it was a windy January day, what seemed like a challenge at first turned into a fantastic experience. We found a charming spot near the doors and alternated between shooting and warming our hands. The grey bricks and the cool winter sky created a perfect harmony, resulting in a beautifully aesthetic shoot.",
        "Amna and Asad, it was a joy to capture your maternity shoot. Despite the January wind, your session turned out beautifully, thanks to your warmth and enthusiasm. Thank you for letting me be a part of this special time in your lives."
      ],
      bannerImage: "/assets/portfolio-item/NewBeginnings/2.webp",
      images: [
        "/assets/portfolio-item/NewBeginnings/1.webp",
        "/assets/portfolio-item/NewBeginnings/2.webp",
        "/assets/portfolio-item/NewBeginnings/3.webp",
        "/assets/portfolio-item/NewBeginnings/4.webp",
        "/assets/portfolio-item/NewBeginnings/5.webp",
        "/assets/portfolio-item/NewBeginnings/6.webp",
        "/assets/portfolio-item/NewBeginnings/7.webp",
        "/assets/portfolio-item/NewBeginnings/8.webp",
        "/assets/portfolio-item/NewBeginnings/9.webp"
      ]
    },


    PrincessGate: {
      title: "Princess Gate",
      description: [
        "31 . 08 . 2024",
        "Samantha and Matthew were one of the first couples I had the pleasure of photographing. On a cold, cloudy March day, we began the shoot at the Princess Gate in Downtown Toronto. The gate provided a stunning architectural backdrop, and we later walked down to Trillium Park, hoping to capture the lake and CN Tower. Unfortunately, the fog had other plans, but it lent a cinematic quality to the shots instead. To warm up, we headed to Sam and Matt’s favorite boba spot, where I captured a few moments that highlighted how boba played a special role in their love story.",
      ],
      bannerImage: "/assets/portfolio-item/PrincessGate/2.webp",
      images: [
        "/assets/portfolio-item/PrincessGate/1.webp",
        "/assets/portfolio-item/PrincessGate/2.webp",
        "/assets/portfolio-item/PrincessGate/3.webp",
        "/assets/portfolio-item/PrincessGate/4.webp",
        "/assets/portfolio-item/PrincessGate/5.webp",
        "/assets/portfolio-item/PrincessGate/6.webp",
        "/assets/portfolio-item/PrincessGate/7.webp",
        "/assets/portfolio-item/PrincessGate/8.webp",
        "/assets/portfolio-item/PrincessGate/9.webp",
        "/assets/portfolio-item/PrincessGate/10.webp",
        "/assets/portfolio-item/PrincessGate/11.webp",
        "/assets/portfolio-item/PrincessGate/12.webp",
        "/assets/portfolio-item/PrincessGate/13.webp",
        "/assets/portfolio-item/PrincessGate/14.webp",
        "/assets/portfolio-item/PrincessGate/15.webp",
      ]
    },

    OldFilm: {
      title: "Old Film",
      description: [
        "10 . 05 . 2024",
        "As they prepared for their wedding in the beautiful city of Ankara, Turkey, my friends Zakia and Erhan wanted to capture the moment they signed their marriage licenses in Toronto. We chose the University of Toronto as the backdrop for the shoot, with its rich blend of architectural styles, evoking a sense of old-world charm reminiscent of a movie set in Venice. After an hour and a half of shooting, we grabbed burgers and they shared the hilarious story of how they met, leaving us all in fits of laughter.",
        "Zakia and Erhan, thank you for your ongoing appreciation of my work. I hope these photos will serve as cherished reminders of your special time before your wedding in Ankara."
      ],
      bannerImage: "/assets/portfolio-item/OldFilm/2.webp",
      images: [
        "/assets/portfolio-item/OldFilm/1.webp",
        "/assets/portfolio-item/OldFilm/2.webp",
        "/assets/portfolio-item/OldFilm/3.webp",
        "/assets/portfolio-item/OldFilm/4.webp",
        "/assets/portfolio-item/OldFilm/5.webp",
        "/assets/portfolio-item/OldFilm/6.webp",
        "/assets/portfolio-item/OldFilm/7.webp",
        "/assets/portfolio-item/OldFilm/8.webp",
        "/assets/portfolio-item/OldFilm/9.webp",
        "/assets/portfolio-item/OldFilm/10.webp",
        "/assets/portfolio-item/OldFilm/11.webp",
        "/assets/portfolio-item/OldFilm/12.webp",
      ]
    },

    SummerGlow: {
      title: "Summer Glow",
      description: [
        "24 . 08 . 2024",
        "Two incredibly talented Indian musicians Kevin and Amy, wanted photos for their Spotify release. On a hot August day, we shot at the Royal Botanical Gardens in Burlington, capturing a nostalgic summer vibe. These images will be featured on Spotify, in magazines, and across their social media platforms.",
        "Kevin and Amy, it was a pleasure to photograph you. Thank you for the kind acknowledgments and for sharing these photos with your audience."
      ],
      bannerImage: "/assets/portfolio-item/SummerGlow/2.webp",
      images: [
        "/assets/portfolio-item/SummerGlow/1.webp",
        "/assets/portfolio-item/SummerGlow/2.webp",
        "/assets/portfolio-item/SummerGlow/3.webp",
        "/assets/portfolio-item/SummerGlow/4.webp",
        "/assets/portfolio-item/SummerGlow/5.webp",
        "/assets/portfolio-item/SummerGlow/6.webp",
        "/assets/portfolio-item/SummerGlow/7.webp",
        "/assets/portfolio-item/SummerGlow/8.webp",
        "/assets/portfolio-item/SummerGlow/9.webp",
      ]
    },

    Seoulmates: {
      title: "Seoulmates",
      description: [
        "24 . 05 . 2023",
        "This love story began in Korea, leading to a beautiful union on a crisp summer day during an intimate ceremony at Toronto City Hall. Surrounded by their closest family members, Michael and Fionn exchanged heartfelt vows, marking the start of their new journey together. After the ceremony, we made our way to Nathan Phillips Square, where the late afternoon sun cast a golden glow, perfect for capturing their love in a series of radiant, sunlit photos.",
        "Michael and Fionn, it was a pleasure and hope we do this again sometime!"
      ],
      bannerImage: "/assets/portfolio-item/Seoulmates/2.webp",
      images: [
        "/assets/portfolio-item/Seoulmates/1.webp",
        "/assets/portfolio-item/Seoulmates/2.webp",
        "/assets/portfolio-item/Seoulmates/3.webp",
        "/assets/portfolio-item/Seoulmates/4.webp",
        "/assets/portfolio-item/Seoulmates/5.webp",
        "/assets/portfolio-item/Seoulmates/6.webp",
        "/assets/portfolio-item/Seoulmates/7.webp",
        "/assets/portfolio-item/Seoulmates/8.webp",
        "/assets/portfolio-item/Seoulmates/9.webp",
        "/assets/portfolio-item/Seoulmates/10.webp",
        "/assets/portfolio-item/Seoulmates/11.webp",
        "/assets/portfolio-item/Seoulmates/12.webp",
      ]
    },

    Healiness: {
      title: "Healiness",
      description: [
        "Ongoing",
        "Visionary leaders committed to transforming wellness and empowerment, Ines and Jean-Christian reached out to me to capture their strength and unity as a couple devoted to helping others. We shot the session in the comfort of their Oakville home, and the images now feature on their website and social media platforms.",
        "Ines and Jean-Christian, thank you for your commitment and for your trust in my work."
       ] ,
      bannerImage: "/assets/portfolio-item/Healiness/2.webp",
      images: [
        "/assets/portfolio-item/Healiness/1.webp",
        "/assets/portfolio-item/Healiness/2.webp",
        "/assets/portfolio-item/Healiness/3.webp",
        "/assets/portfolio-item/Healiness/4.webp",
        "/assets/portfolio-item/Healiness/5.webp",
        "/assets/portfolio-item/Healiness/6.webp",
        "/assets/portfolio-item/Healiness/7.webp",
        "/assets/portfolio-item/Healiness/8.webp",
        "/assets/portfolio-item/Healiness/9.webp",
      ]
    },

    BestofJune: {
      title: "Best of June",
      description: [
        "Ongoing",
        "Award-winning Zoroastrian author, Tanaz Bhathena is known for her contemporary and fantasy fiction. We first met while I was photographing her nephew’s wedding. Later, she reached out to me for a portrait photoshoot to use on her website, social media campaigns, and as author images for her books. Tanaz wanted a candid and naturally spirited shoot, so we chose the Riverwood Conservancy in Mississauga. Accompanied by her father, we strolled through the park, capturing some fun moments, including a bit of bird watching. The resulting images now grace her website and social media platforms.",
        "Tanaz, it was an absolute privilege to photograph your author portraits in your vibrant spirit!"
      ],
      bannerImage: "/assets/portfolio-item/BestofJune/2.webp",
      images: [
        "/assets/portfolio-item/BestofJune/1.webp",
        "/assets/portfolio-item/BestofJune/2.webp",
        "/assets/portfolio-item/BestofJune/3.webp",
        "/assets/portfolio-item/BestofJune/4.webp",
        "/assets/portfolio-item/BestofJune/5.webp",
        "/assets/portfolio-item/BestofJune/6.webp",
        "/assets/portfolio-item/BestofJune/7.webp",
        "/assets/portfolio-item/BestofJune/8.webp",
        "/assets/portfolio-item/BestofJune/9.webp",
      ]
    },

    Harmonies: {
      title: "Harmonies",
      description: [
        "24 . 08 . 2024",
        "Two incredibly talented Indian musicians, Kevin & Amy, wanted branding photos for their Spotify playlist cover. On a hot August day, these series were shot in the greenhouses of beautiful Royal Botanical Gardens in Burlington, capturing a refreshing take. These images will be featured on Spotify, in magazines, and across their social media platforms.",
        "Kevin and Amy, it was a pleasure to photograph you. Thank you for the kind acknowledgments and for sharing these photos with your audience."
      ],
      bannerImage: "/assets/portfolio-item/Harmonies/2.webp",
      images: [
        "/assets/portfolio-item/Harmonies/1.webp",
        "/assets/portfolio-item/Harmonies/2.webp",
        "/assets/portfolio-item/Harmonies/3.webp",
        "/assets/portfolio-item/Harmonies/4.webp",
        "/assets/portfolio-item/Harmonies/5.webp",
        "/assets/portfolio-item/Harmonies/6.webp",
        "/assets/portfolio-item/Harmonies/7.webp",
        "/assets/portfolio-item/Harmonies/8.webp",
        "/assets/portfolio-item/Harmonies/9.webp",
      ]
    },

    Tulum: {
      title: "Tulum",
      description: [
        "December . 2023",
        "Seeking to escape the harsh Canadian winter, I spent the December holidays soaking up the warmth of Mexico. Tulum, with its vibrant streets and lively tourist atmosphere, offered endless sights to capture.",
        "Among the many captivating scenes, one stood out—a group dressed entirely in white, calling themselves The River People. Despite the language barrier, their presence was so intriguing that I felt compelled to preserve the moment through my lens.",
        "Later, I indulged in a delicious Mexican treat—Marquesitas, a crispy crepe dessert filled with a delightful mix of flavors that perfectly captured the essence of local cuisine.",
      ],
      bannerImage: "/assets/portfolio-item/Tulum/2.webp",
      images: [
        "/assets/portfolio-item/Tulum/1.webp",
        "/assets/portfolio-item/Tulum/2.webp",
        "/assets/portfolio-item/Tulum/3.webp",
        "/assets/portfolio-item/Tulum/4.webp",
        "/assets/portfolio-item/Tulum/5.webp",
        "/assets/portfolio-item/Tulum/6.webp",
        "/assets/portfolio-item/Tulum/7.webp",
        "/assets/portfolio-item/Tulum/8.webp",
        "/assets/portfolio-item/Tulum/9.webp",
      ]
    },

    Cancun: {
      title: "Cancun",
      description: [
        "December . 2023",
        "Seeking to escape the harsh Canadian winter, I spent the December holidays soaking up the warmth of Mexico. Cancun, with its vibrant mix of rich culture and lively tourist atmosphere, offered endless sights to capture. On my last day, as the sun dipped low, I couldn’t resist taking out my camera to chase the evening light and photograph a breathtaking sunset and later capture the beachouse we had dinner in.",
       ],
      bannerImage: "/assets/portfolio-item/Cancun/2.webp",
      images: [
        "/assets/portfolio-item/Cancun/1.webp",
        "/assets/portfolio-item/Cancun/2.webp",
        "/assets/portfolio-item/Cancun/3.webp",
        "/assets/portfolio-item/Cancun/4.webp",
        "/assets/portfolio-item/Cancun/5.webp",
        "/assets/portfolio-item/Cancun/6.webp",
        "/assets/portfolio-item/Cancun/7.webp",
        "/assets/portfolio-item/Cancun/8.webp",
      ]
    },

    Banff: {
      title: "Banff",
      description: [
        "May . 2023",
        "I ventured to Banff in spring, where the lakes were still mostly frozen, creating a stunning wintery contrast against the emerging spring landscape. Despite the chill, the experience was exhilarating, and capturing the dramatic scenery was a true highlight.",
       ],
      bannerImage: "/assets/portfolio-item/Banff/2.webp",
      images: [
        "/assets/portfolio-item/Banff/1.webp",
        "/assets/portfolio-item/Banff/2.webp",
        "/assets/portfolio-item/Banff/3.webp",
        "/assets/portfolio-item/Banff/4.webp",
        "/assets/portfolio-item/Banff/5.webp",
        "/assets/portfolio-item/Banff/6.webp",
      ]
    },
    

    // Add more portfolio items here
    // Add more portfolio items here
  };
  
  export default portfolioData;
  