// data-highlightData.js
const highlightData = {
  Weddings: {
    images: [

      "/assets/portfolio-item/Union/6.webp",
      "/assets/portfolio-item/Union/7.webp",
      "/assets/portfolio-item/Union/8.webp",
      "/assets/portfolio-item/Union/9.webp",
      "/assets/portfolio-item/WhiteRoses/11.webp",
      "/assets/portfolio-item/WhiteRoses/12.webp",
      "/assets/portfolio-item/WhiteRoses/13.webp",
      "/assets/portfolio-item/WhiteRoses/14.webp",
      "/assets/portfolio-item/WhiteRoses/15.webp",
      "/assets/portfolio-item/TimelessElegance/17.webp",
      "/assets/portfolio-item/TimelessElegance/18.webp",
      "/assets/portfolio-item/TimelessElegance/19.webp",
      "/assets/portfolio-item/TimelessElegance/20.webp",
      "/assets/portfolio-item/TimelessElegance/21.webp",
      "/assets/portfolio-item/PrincessGate/5.webp",
      "/assets/portfolio-item/PrincessGate/6.webp",
      "/assets/portfolio-item/PrincessGate/7.webp",
      "/assets/portfolio-item/PrincessGate/8.webp",
      "/assets/portfolio-item/PrincessGate/9.webp",
      "/assets/portfolio-item/PrincessGate/10.webp",
      "/assets/portfolio-item/FirstDate/6.webp",
      "/assets/portfolio-item/FirstDate/7.webp",
      "/assets/portfolio-item/FirstDate/8.webp",
      "/assets/portfolio-item/FirstDate/9.webp",
      "/assets/portfolio-item/OldFilm/3.webp",
      "/assets/portfolio-item/OldFilm/4.webp",
      "/assets/portfolio-item/OldFilm/5.webp",
      "/assets/portfolio-item/OldFilm/6.webp",
      "/assets/portfolio-item/OldFilm/7.webp",
      "/assets/portfolio-item/OldFilm/8.webp",
      "/assets/portfolio-item/OldFilm/9.webp",
      "/assets/portfolio-item/OldFilm/10.webp",
      "/assets/portfolio-item/OldFilm/11.webp",
      "/assets/portfolio-item/Seoulmates/4.webp",
      "/assets/portfolio-item/Seoulmates/5.webp",
      "/assets/portfolio-item/Seoulmates/6.webp",
      "/assets/portfolio-item/Seoulmates/7.webp",
      "/assets/portfolio-item/Seoulmates/8.webp",
      "/assets/portfolio-item/Seoulmates/9.webp",
      "/assets/portfolio-item/Seoulmates/10.webp",
      "/assets/portfolio-item/Seoulmates/11.webp",
    ]
  },

    Maternity: {
      images: [
        "/assets/portfolio-item/SandSea/1.webp",
        "/assets/portfolio-item/SandSea/2.webp",
        "/assets/portfolio-item/SandSea/3.webp",
        "/assets/portfolio-item/SandSea/4.webp",
        "/assets/portfolio-item/SandSea/5.webp",
        "/assets/portfolio-item/SandSea/6.webp",
        "/assets/portfolio-item/LilNewYorker/2.webp",
        "/assets/portfolio-item/LilNewYorker/3.webp",
        "/assets/portfolio-item/LilNewYorker/4.webp",
        "/assets/portfolio-item/LilNewYorker/5.webp",
        "/assets/portfolio-item/LilNewYorker/6.webp",
        "/assets/portfolio-item/LilNewYorker/7.webp",
        "/assets/portfolio-item/LilNewYorker/8.webp",
        "/assets/portfolio-item/NewBeginnings/3.webp",
        "/assets/portfolio-item/NewBeginnings/4.webp",
        "/assets/portfolio-item/NewBeginnings/5.webp",
        "/assets/portfolio-item/NewBeginnings/6.webp",
        "/assets/portfolio-item/NewBeginnings/7.webp",
      ]
    },

    Family: {
      images: [
        "/assets/portfolio-item/Union/1.webp",
        "/assets/portfolio-item/Union/2.webp",
        "/assets/portfolio-item/Union/3.webp",
        "/assets/portfolio-item/Union/4.webp",
        "/assets/portfolio-item/Union/5.webp",
        "/assets/portfolio-item/Union/6.webp",
        "/assets/portfolio-item/Union/7.webp",
        "/assets/portfolio-item/Union/8.webp",
        "/assets/portfolio-item/Union/9.webp",
        "/assets/portfolio-item/Union/10.webp",
        "/assets/portfolio-item/Union/11.webp",
        "/assets/portfolio-item/Union/12.webp"
      ]

    },

    Travel: {
      images: [
        "/assets/portfolio-item/Banff/1.webp",
        "/assets/portfolio-item/Banff/2.webp",
        "/assets/portfolio-item/Banff/3.webp",
        "/assets/portfolio-item/Banff/4.webp",
        "/assets/portfolio-item/Banff/5.webp",
        "/assets/portfolio-item/Banff/6.webp",
        "/assets/portfolio-item/Tulum/1.webp",
        "/assets/portfolio-item/Tulum/2.webp",
        "/assets/portfolio-item/Tulum/3.webp",
        "/assets/portfolio-item/Tulum/4.webp",
        "/assets/portfolio-item/Tulum/5.webp",
        "/assets/portfolio-item/Tulum/6.webp",
        "/assets/portfolio-item/Tulum/7.webp",
        "/assets/portfolio-item/Tulum/8.webp",
        "/assets/portfolio-item/Tulum/9.webp",
        "/assets/portfolio-item/Cancun/1.webp",
        "/assets/portfolio-item/Cancun/2.webp",
        "/assets/portfolio-item/Cancun/3.webp",
        "/assets/portfolio-item/Cancun/4.webp",
        "/assets/portfolio-item/Cancun/5.webp",
        "/assets/portfolio-item/Cancun/6.webp",
        "/assets/portfolio-item/Cancun/7.webp",
        "/assets/portfolio-item/Cancun/8.webp",
      ]
    },

    Couples: {
      images: [
        "/assets/portfolio-item/WhisperedVows/5.webp",
        "/assets/portfolio-item/WhisperedVows/6.webp",
        "/assets/portfolio-item/WhisperedVows/7.webp",
        "/assets/portfolio-item/WhisperedVows/8.webp",
        "/assets/portfolio-item/WhisperedVows/9.webp",
        "/assets/portfolio-item/WhisperedVows/10.webp",
        "/assets/portfolio-item/WhisperedVows/11.webp",
        "/assets/portfolio-item/WhisperedVows/12.webp",
        "/assets/portfolio-item/BlushofOlympus/1.webp",
        "/assets/portfolio-item/BlushofOlympus/2.webp",
        "/assets/portfolio-item/BlushofOlympus/3.webp",
        "/assets/portfolio-item/BlushofOlympus/4.webp",
        "/assets/portfolio-item/BlushofOlympus/5.webp",
        "/assets/portfolio-item/ARedKnot/5.webp",
        "/assets/portfolio-item/ARedKnot/6.webp",
        "/assets/portfolio-item/ARedKnot/7.webp",
        "/assets/portfolio-item/ARedKnot/8.webp",
        "/assets/portfolio-item/ARedKnot/9.webp",
        "/assets/portfolio-item/ARedKnot/10.webp",
        "/assets/portfolio-item/SummerGlow/1.webp",
        "/assets/portfolio-item/SummerGlow/2.webp",
        "/assets/portfolio-item/SummerGlow/3.webp",
        "/assets/portfolio-item/SummerGlow/4.webp",
        "/assets/portfolio-item/SummerGlow/5.webp",
        "/assets/portfolio-item/SummerGlow/6.webp",
        "/assets/portfolio-item/SummerGlow/7.webp",
        "/assets/portfolio-item/SummerGlow/8.webp",
      ]
    },

    Branding: {
      images: [
        "/assets/portfolio-item/Healiness/1.webp",
        "/assets/portfolio-item/Healiness/2.webp",
        "/assets/portfolio-item/Healiness/3.webp",
        "/assets/portfolio-item/Healiness/4.webp",
        "/assets/portfolio-item/Healiness/5.webp",
        "/assets/portfolio-item/Healiness/6.webp",
        "/assets/portfolio-item/Healiness/7.webp",
        "/assets/portfolio-item/Healiness/8.webp",
        "/assets/portfolio-item/Healiness/9.webp",
        "/assets/portfolio-item/BestofJune/3.webp",
        "/assets/portfolio-item/BestofJune/4.webp",
        "/assets/portfolio-item/BestofJune/5.webp",
        "/assets/portfolio-item/BestofJune/6.webp",
        "/assets/portfolio-item/BestofJune/7.webp",
        "/assets/portfolio-item/BestofJune/8.webp",
        "/assets/portfolio-item/Harmonies/2.webp",
        "/assets/portfolio-item/Harmonies/3.webp",
        "/assets/portfolio-item/Harmonies/4.webp",
        "/assets/portfolio-item/Harmonies/5.webp",
        "/assets/portfolio-item/Harmonies/6.webp",
        "/assets/portfolio-item/Harmonies/7.webp",
        "/assets/portfolio-item/Harmonies/8.webp",
        "/assets/portfolio-item/Harmonies/9.webp",
      ]
    },

  };
  
  export default highlightData;
  