import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import "../../css/portfolio.css";
import highlightData from './highlight-data';
import {images} from "./portfolio-item-data";
import { hideAllItems, showItemsByCategory} from '../../utils/helpers';
import { useLocation } from "react-router-dom";

const Portfolio = () => {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState(location.state?.activeCategory || 'Weddings');
  const [highlightMode, setHighlightMode] = useState(true);

  const filterRef = useRef(null);
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const handleToggle = (mode) => {
    if(mode ==='highlight-mode'){
      setHighlightMode(true);
    }else{
      setHighlightMode(false);
    }
  };

  const handleCategoryClick = (name) => {
    setActiveCategory(name);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  useEffect(() => {
    if (!highlightMode) {
      hideAllItems();
      showItemsByCategory(activeCategory);
    }
    if (filterRef.current) {
      const activeElement = filterRef.current.querySelector(`.filter-btn.active`);
      if (activeElement) {
        const { offsetLeft, offsetWidth } = activeElement;
        setBackgroundStyle({
          width: `${offsetWidth}px`,
          transform: `translateX(${offsetLeft}px)`
        });
      }
    }


  }, [activeCategory, highlightMode]);

  const categories = [
    // { name: 'featured', label: 'Featured' },
    { name: 'Weddings', label: 'Weddings' },
    { name: 'Couples', label: 'Couples' },
    { name: 'Maternity', label: 'Maternity' },
    { name: 'Branding', label: 'Branding' },
    { name: 'Travel', label: 'Travel' }
  ];

  return (
    <section id='portfolio-page'>
      <div className='top-container'>
        <h1 className='bigNumber'>PORTFOLIO</h1>

        <div id="filter" ref={filterRef}>
          <div
            className="filter-background"
            style={backgroundStyle}
          />
          {categories.map(category => (
            <div
              key={category.name}
              className={`filter-btn ${activeCategory === category.name ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.label}
            </div>
          ))}
        </div>

        <div className="toggle-container">
          <span className={`toggle-option ${highlightMode ? "active" : ""}`} onClick={()=>handleToggle('highlight-mode')}>Highlights</span>
          <div
            className={`toggle-switch ${highlightMode ? "highlight-mode" : "gallery-mode"}`}>
            <div className="toggle-indicator"></div>
          </div>
          <span className={`toggle-option ${highlightMode ? "" : "active"}`}  onClick={()=>handleToggle('gallery-mode')}>Galleries</span>
        </div>

      </div>

      <div className='bottom-container'>
      {/* Highlight Mode */}
      {highlightMode ? (
        <div className={`highlight-container ${highlightMode ? "fade-in" : "fade-out"}`}>
          <div className="highlights-grid" >
            {highlightData[activeCategory]?.images.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Highlight ${index + 1}`}
                style={{ '--index': index }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div id="gallery" className={`gallery ${!highlightMode ? "fade-in" : "fade-out"}`}>
        {images[activeCategory]?.map(({ src, title, name, date, description }, index) => (
          <div key={index} className={`item ${activeCategory}`}>
            <div className="photo">
              <img src={src} alt={`Image ${index + 1}`} />
            </div>
            <div className="details">
              <div>
                <h1>0{index + 1}</h1>
                <p style={{ fontWeight: "600" }}>{title}</p>
                {name && <p>{name}</p>}
              </div>
              <div>
                {date && <p style={{ fontWeight: "600" }}>{date}</p>}
                <p>{description}</p>
              </div>
              <Link
                to={`/portfolio/${title.replace(/\s+/g, "").replace(/&/g, "and")}`}
                className="accent-btn"
              >
                <span className="btn-circle"></span>
              </Link>
            </div>
          </div>
        ))}
      </div>
      )}
      </div>
    </section>
  );
};

export default Portfolio;
