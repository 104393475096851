import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../css/services.css";
import { Link } from 'react-scroll';

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  useEffect(() => {
    const pinWrap = document.querySelector(".pin-wrap");
    const pinWrapWidth = pinWrap.offsetWidth;
    const horizontalScrollLength = pinWrapWidth - (window.innerWidth / 2.8);

    const animation = gsap.to(".pin-wrap", {
      scrollTrigger: {
        scrub: 1,
        trigger: "#sectionPin",
        pin: true,
        start: "top top",
        end: () => `+=${horizontalScrollLength}`,
        invalidateOnRefresh: true,
      },
      x: -horizontalScrollLength,
      ease: "none",
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 50);

    // Cleanup
    return () => {
      animation.kill();
      ScrollTrigger.getAll().forEach(st => st.kill());
    };
  }, []);

  return (
    <div className="container">

      <section id="sectionPin">
        <div className="pin-wrap">
        <h2 style={{width: "70%", textAlign: "start"}}>
          These are your <br />“once-in-a-lifetime” moments
        </h2>
          <img src="/assets/portfolio-item/OldFilm/9.webp" alt="Portfolio 1" />
          <img src="/assets/portfolio-item/OldFilm/3.webp" alt="Portfolio 2" />
          <img src="/assets/portfolio-item/OldFilm/8.webp" alt="Portfolio 3" />
          <img src="/assets/portfolio-item/TimelessElegance/16.webp" alt="Portfolio 4" />
          <img src="/assets/portfolio-item/TimelessElegance/6.webp" alt="Portfolio 5" />
          <img src="/assets/portfolio-item/TimelessElegance/8.webp" alt="Portfolio 6" />
          <img src="/assets/portfolio-item/WhiteRoses/12.webp" alt="Portfolio 7" />
          <img src="/assets/portfolio-item/WhiteRoses/15.webp" alt="Portfolio 8" />
          <img src="/assets/portfolio-item/WhiteRoses/18.webp" alt="Portfolio 9" />
          <p style={{textAlign: "start"}}>
          Planning a wedding or any special milestone can feel overwhelming — I've been there! 
          But one thing I've learned is that photos are one of the most meaningful investments you can make. 
          Whether it's your big day, an intimate celebration, or a moment worth cherishing, 
          your photos will serve as lasting memories for generations!
        </p>
        </div>
      </section>


      <div style={{position: 'relative'}}>
      <h4 className="subtext heading right services">&mdash; Services</h4>
      </div>
      
      {/* Services Section */}


      <section id="services" className="aboutServices" style={{ position: "relative", zIndex: 1, paddingTop: "50px" }}>
        <div className="left-col">
          <h1 className="services-title">
            <span className="title-default">Dreamy, Timeless & Authentic</span>
            <span className="title-hover">Serving Toronto, GTA & the World</span>
          </h1>
        </div>

        <div className="right-col">
          <div className="service">
            <div className="service-header">
              <h3>Weddings</h3>
              <div className="download-arrow"></div>
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
              <div>
                <p>
                  4, 6, 8, or 10 hours of coverage<br />
                  500-1,000+ digital images<br />
                  30-60 min engagement shoot
                </p>
              </div>
              <div>
                <p>
                  Second Professional Photographer<br />
                  Personalized timeline assistance<br />
                  Full gallery within 3 weeks
                </p>
              </div>
            </div>
          </div>


          <div className="service">
            <div className="service-header">
              <h3>Couples</h3>
              <div className="download-arrow"></div>
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
            <p>
              For engagements, proposals, elopements, maternity, or just because. Each session includes 60 minutes of shooting, 75+ digital photos, a what to wear guide if interested, printing rights, and full gallery within 1 week.
            </p>
            </div>
          </div>

          <div className="service">
            <div className="service-header">
              <h3>Others</h3>
              <div className="download-arrow"></div>
            </div>
            <div className="horizontalSeperator"></div>
            <div className="text-col">
            <p>
              For portraits, family, branding, or lifestyle, each session includes 60+ minutes of shooting, 75+ digital photos, printing rights, and full gallery within 1 week.
            </p>
            </div>
          </div>
          <Link to="contact" className='custom-link' smooth={true} duration={1000}>Inquire Now for your Customised Quote</Link>
        </div>
      </section>

    </div>
  );
};

export default Services;
