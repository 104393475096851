import React from 'react';
import { Link } from 'react-router-dom';
import '../css/variables.css'

const NotFound = () => {
  return (
    <section className='notFound'>
      <h1 className='big-number'>404</h1>
      <br/>
      <h3>
        Oops! The page you're looking for doesn't exist.
      </h3>
      <br/>
      <Link to="/">
        Go back to the homepage
      </Link>
      <br/>
    </section>
  );
};

export default NotFound;
