import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Work from './components/Work';
import Intro from './components/Intro';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Footer from './components/Footer';
import Testimonials from './components/Testimonials';
import Portfolio from './components/Pages/Portfolio';
import PortfolioItem from './components/Pages/PortfolioItem';
import NotFound from './components/NotFound';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './css/App.css';
import { initTextAnimations } from './utils/animations';

function AppContent() {
  const [isHeroLoaded, setIsHeroLoaded] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    // Disable browser's default scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    };

    scrollToTop();
    window.addEventListener('load', scrollToTop);
    window.addEventListener('popstate', scrollToTop);

    return () => {
      window.removeEventListener('load', scrollToTop);
      window.removeEventListener('popstate', scrollToTop);
    };
  }, []);

  useEffect(() => {
    if (isHomePage) {
      // Apply hero load animation only on homepage
      setTimeout(() => {
        setIsHeroLoaded(true);
        document.body.style.overflowY = 'auto';
      }, 1500);
    } else {
      // Immediately set loaded state for other pages
      setIsHeroLoaded(true);
      document.body.style.overflowY = 'auto';
    }
  }, [isHomePage]);

  return (
    
    <div className={`App ${isHeroLoaded ? 'loaded' : ''}`}>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero isHeroLoaded={isHeroLoaded} />
              <Intro />
              <Work />
              <About />
              <Services />
              <Testimonials />
            </>
          }
        />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:title" element={<PortfolioItem />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <GoogleReCaptchaProvider reCaptchaKey="your-recaptcha-key">
        <Contact />
      </GoogleReCaptchaProvider>
      <Footer />
    </div>
  );
}

function App() {
  useEffect(() => {
    initTextAnimations();
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
